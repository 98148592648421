import { createLogic } from 'redux-logic';
import post from './reducer';

const { actions } = post;
const {
  fetchPosts, fetchPostsSuccess, error, fetchPost, fetchPostSuccess,
} = actions;

export const fetchPostsLogic = createLogic({
  type: fetchPosts.type,
  processOptions: {
    successType: fetchPostsSuccess,
    failType: error,
  },
  process({ httpClient }) {
    return httpClient.get('posts').then((response) => response.data);
  },
});

export const fetchPostLogic = createLogic({
  type: fetchPost.type,
  processOptions: {
    successType: fetchPostSuccess,
    failType: error,
  },
  process({ action, httpClient }) {
    return httpClient.get(`posts/${action.payload}`).then((response) => response.data);
  },
});
