import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const DefaultLayout = lazy(() => import('./DefaultLayout'));
const Login = lazy(() => import('./Login'));
const Admin = lazy(() => import('./Admin'));

function App({ store }) {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/admin/login" component={Login} exact />
            <Route path="/admin" component={Admin} exact />
            <Route path="/" component={DefaultLayout} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.shape({}).isRequired,
};

export default App;
