import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'posts',
  initialState: [],
  reducers: {
    error: (state) => state,
    fetchPosts: (state) => state,
    fetchPostsSuccess: (state, action) => action.payload,
    fetchPost: (state) => state,
    fetchPostSuccess: (state, action) => { state.push(action.payload); },
  },
});
