import { combineReducers } from 'redux';
import post from './reducer';
import selector from './selectors';
import { fetchPostsLogic, fetchPostLogic } from './logic';

export const { fetchPosts, fetchPost } = post.actions;
export const { setSelectedSlug } = selector.actions;
export const postLogic = [fetchPostsLogic, fetchPostLogic];

export default combineReducers({
  posts: post.reducer,
  postSelector: selector.reducer,
});
