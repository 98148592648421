import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogicMiddleware } from 'redux-logic';
import axios from 'axios';

import reducer, { postLogic } from './post';

const logic = [...postLogic];
const deps = {
  httpClient: axios.create({
    baseURL: '/api',
  }),
};
const logicMiddlreware = createLogicMiddleware(logic, deps);

export default configureStore({
  reducer,
  middleware: [...getDefaultMiddleware(), logicMiddlreware],
});
