import { createSlice, createSelector } from '@reduxjs/toolkit';

export default createSlice({
  name: 'postSelector',
  initialState: '',
  reducers: {
    setSelectedSlug: (state, action) => action.payload,
  },
});

export const selectPosts = (state) => state.posts;
export const selectSlug = (state) => state.postSelector;
export const selectPost = createSelector(
  [selectPosts, selectSlug],
  (posts, slug) => posts.find((post) => post.slug === slug),
);
